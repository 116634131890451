<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>Usuarios</v-card-title>
      <UserList ></UserList>
    </v-card>


  </div>
</template>

<script>

import UserList from '@/components/Users/UserList';




export default {
 components: {
    UserList,
},
 setup() {
    return {
      // icons
      icons: {
      },
    }
  },

}
</script>