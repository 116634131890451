<template>
    <v-dialog
        v-model="showingDeleteUserModal"
        width="auto"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            ¿Eliminar usuario '{{ user.username }}'?
          </v-card-title>
  
          <v-divider></v-divider>
          <v-card-text>
            <div>
                Seleccionar la acción que desea realizar.
            </div>
            <div>
              <v-autocomplete
              ref="selectedOption"
              v-model="selectedOption"
              :rules="[() => !!selectedOption || 'Campo requerido']"
              :items="deleteOptions"
              placeholder="Eliminar.."
              required
              ></v-autocomplete>
            </div>
          </v-card-text>
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
              color="primary" 
              text 
              @click="close()"
            >
              CANCELAR
            </v-btn>         
            <v-btn
              color="error"
              @click="deleteUser()"
              v-if="selectedOption === 'Eliminar usuario completo'"
            >
              Eliminar usuario
            </v-btn>
            <v-btn
              color="error"
              @click="deleteUserCompany()"
              v-if="selectedOption === 'Eliminar vínculo con la empresa'"
            >
              Eliminar vínculo
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </template>

<script>
  
    export default {
  
      methods: {
        open(user) {
          this.showingDeleteUserModal = true
          this.user = user
          this.getCompanyIds(user.user_id)
        },
        

        close() {
          this.showingDeleteUserModal = false
        },
        
        async deleteUser(){
            try {
                const response = await this.$api.deleteUser(this.user.user_id)
                this.$emit('reload-user-list')
                this.close()
            } catch (e) {
                console.log(e)
        }
        },

        async getCompanyIds(id) {
          const response = await this.$api.getUser(id)
          this.userCompanyIds = response.data.company_id
        },

        async deleteUserCompany() {
          try {
            const userCompanyIdsFiltered = this.allCompaniesData.filter(el => this.userCompanyIds.includes(el.id))
            const userCompanyToDelete = [];
            userCompanyIdsFiltered.forEach(el => {
              if(el.name === this.user.company) {
                userCompanyToDelete.push(el.id)
              }
            })
            const deleteResponse = await this.$api.deleteUserCompany(this.user.user_id, userCompanyToDelete)
            this.$emit('reload-user-list')
            this.close()
          } catch(err) {
            console.log(err)
          }
        }

      },

      data: () => {
        return {
          showingDeleteUserModal: false,
          user: {},
          userCompanyIds: [],
          selectedOption:'',
          deleteOptions: ['Eliminar usuario completo', 'Eliminar vínculo con la empresa']
        }
      },
      computed: {
        allCompaniesData() { return this.$store.getters['session/getAllCompaniesList']() },
      },
    }
    </script>