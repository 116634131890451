<template>
    <div>
		<v-card>
			<v-divider class="mt-4"></v-divider>

			<!-- actions -->
			<v-card-text class="d-flex align-center flex-wrap pb-0">
				<!-- search -->
				<v-text-field
					v-model="searchQuery"
					@keyup="handleSearch"
					placeholder="Buscar por nombre de usuario y empresa"
					outlined
					hide-details
					dense
					class="user-search me-3 mb-4"
				>
				</v-text-field>

				<v-spacer></v-spacer>
				<v-select
					style="width: 0px"
					class="mb-4"
					:items="[{name: '-', value: -1}, {name: 'Administrador', value: 0}, {name: 'Adm. de empresa', value: 1}, {name: 'Trabajador', value: 2}]"
					hide-details="true"
					v-model="stateFilterRole"
					label="Rol"
					item-text="name"
					item-value="value"
					@change="setFilterRole"
					outlined
					dense
				></v-select>
				<v-spacer></v-spacer>

				<div class="d-flex align-center flex-wrap">
				<v-btn
					color="primary"
					class="mb-4 me-3"
					v-if="getUserRole === 0 || 1"
					@click="$refs.user_form_modal.openModal()"
				>
				<v-icon>{{ icons.mdiPlus }}</v-icon>
				<span>Agregar Usuario</span>
				</v-btn>

			</div>
			</v-card-text>
				<!-- table -->
			<v-data-table
				:headers="tableColumns"
				:page="current_page"
				:items="userListTable"
				:options.sync="options"
				:server-items-length="totalUserListTable"
				:loading="loading"
				:footer-props="{ 'items-per-page-options': [5, 10, 15] }"
				@update:page="changingUsersPage($event)"
      	@update:items-per-page="changingUsersItemsPerPage($event)"
			>
				<!-- show-select -->

        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              No hay
              <strong>Usuarios</strong>
              disponibles para esta búsqueda
            </v-list-item-title>
          </v-list-item>
        </template>

				<!-- name -->
				<template #[`item.username`]="{item}">
					<v-btn
						class="no-uppercase"
						text
						@click="$refs.user_details_modal.open(item.user_id)"
					>
						{{ item.username }}
					</v-btn>
				</template>

				<!-- name -->
				<template #[`item.role`]="{item}">
					{{ roleParser[item.role] }}
				</template>

				<template #[`item.credits`]="{item}">
					{{ item.credits? item.credits : '0' }}
				</template>

				<!-- edit -->
				<template #[`item.edit`]="{item}">
					<v-btn
						icon
						color="primary"
						@click="$refs.user_form_modal.openModal(item.user_id)"
						:disabled="getUserRole === 1 && item.role != 'Worker' || getUserRole === 2"
					>
						<v-icon>
							{{ icons.mdiSquareEditOutline }}
						</v-icon>
					</v-btn>
				</template>

				<!-- delete -->
				<template #[`item.delete`]="{item}">
					<v-btn
						icon
						@click="$refs.delete_user_modal.open(item)"
						:disabled="getUserRole === 1 && item.role != 'Worker' || getUserRole === 2"
					>
						<v-icon class="error--text">
							{{ item.enabled? icons.mdiDeleteOutline : icons.mdiDeleteOutline }}
						</v-icon>
					</v-btn>
				</template>

			</v-data-table>

			<user-details-modal
				ref="user_details_modal"
			/>
				<!-- @reload-user-list="getUsersList()" -->

			<user-form-modal
				ref="user_form_modal"
				@reload-user-list="getUsersList()"
			/>

			<delete-user-modal
				ref="delete_user_modal"
				@reload-user-list="getUsersList()"
			/>

		</v-card>
    </div>
</template>

<script>
const debounce = require('debounce');


import { ref } from '@vue/composition-api';
import UserFormModal from '@/components/Users/UserFormModal';
import DeleteUserModal from '@/components/Users/DeleteUserModal';
import UserDetailsModal from '@/components/Users/UserDetailsModal';
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiSquareEditOutline,
} from '@mdi/js'


    export default {

        components: {
            UserFormModal,
            DeleteUserModal,
			UserDetailsModal,
        },

        data: () => {
            return {
                current_page: 1,
                page_size: 10,

                enabled_toggle_confirm_modal: false,
                enabledFilterValue: -1,
                allUsersData: [],
				stateFilterRole: -1,
				roleParser: {
					Admin: "Administrador",
					Worker: "Trabajador",
					Admin_company: "Adm. de empresa"
				}
            }
        },
        mounted() {
					if(this.getSelectedCompany) {
            this.loading = true
            this.getUsersList()
					}
        },
        methods: {
			setFilterRole() {
				this.current_page = 1
      			this.getUsersList()
			},

		changingUsersPage(e) {
      this.current_page = e
      this.getUsersList()
    },

    changingUsersItemsPerPage(e) {
      this.page_size = e
      this.getUsersList()
    },

            async getUsersList() {
                this.loading = true

                const data = {
                    page_size: this.page_size,
                    page: this.current_page,
                    search: this.searchQuery,
                    company_id: this.getSelectedCompany,
					role: this.stateFilterRole,
				}

                try {
                    const response = await this.$api.getUsersList(data)
                    // console.log(response)
                    this.userListTable = response.results
                    this.totalUserListTable = response.count
                } catch (error) {
                    console.log(error)
                } finally {
                    this.loading = false
                }
            },

            handleSearch: debounce(function (e) {
                // console.log(this.searchQuery)
                this.current_page = 1
                this.getUsersList()
			}, 400),

        },

        computed: {
            getUserRole() { return this.$store.getters['session/getUserRole']() },
            getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
        },

        watch: {
            getSelectedCompany(newValue) {
                if (newValue) {
                    this.current_page = 1
                    this.getUsersList()
                }
            }
        },

        setup() {
            const totalUserListTable = ref(0);
            const userListTable = ref([]);

            const options = ref({
                sortBy: ['id'],
                sortDesc: [true],
                })

            const tableColumns = [
				{ text: 'USUARIO', value: 'username', align: 'center', sortable: false },
				{ text: 'ROL', value: 'role', align: 'center', sortable: false },
				{ text: 'EMPRESA', value: 'company', align: 'center', sortable: false },
				{ text: 'CRÉDITOS', value: 'credits', align: 'center', sortable: false },
				{ text: 'EDITAR', value: 'edit', align: 'center', sortable: false },
				{ text: 'ELIMINAR', value: 'delete', align: 'center', sortable: false },
            ];

            const loading = ref(false);
            const searchQuery = ref('');

            return {
                totalUserListTable,
                userListTable,
                tableColumns,
                loading,
                options,
                searchQuery,

                // icons
                icons: {
                    mdiDeleteOutline,
                    mdiPlus,
                    mdiCheckCircleOutline,
                    mdiCloseCircleOutline,
                    mdiSquareEditOutline,
                },
            }
        }
    }
</script>

<style scoped>
    .no-uppercase {
        text-transform: unset !important;
	}
</style>
