import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"auto"},model:{value:(_vm.showingDeleteUserModal),callback:function ($$v) {_vm.showingDeleteUserModal=$$v},expression:"showingDeleteUserModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" ¿Eliminar usuario '"+_vm._s(_vm.user.username)+"'? ")]),_c(VDivider),_c(VCardText,[_c('div',[_vm._v(" Seleccionar la acción que desea realizar. ")]),_c('div',[_c(VAutocomplete,{ref:"selectedOption",attrs:{"rules":[function () { return !!_vm.selectedOption || 'Campo requerido'; }],"items":_vm.deleteOptions,"placeholder":"Eliminar..","required":""},model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}})],1)]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CANCELAR ")]),(_vm.selectedOption === 'Eliminar usuario completo')?_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteUser()}}},[_vm._v(" Eliminar usuario ")]):_vm._e(),(_vm.selectedOption === 'Eliminar vínculo con la empresa')?_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteUserCompany()}}},[_vm._v(" Eliminar vínculo ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }