
<template>
  <v-dialog
    v-model="showing_modal"
    width="500"
  >
    <v-card>
      <v-card-text v-if="loading">
        Cargando...
        <v-progress-linear
          indeterminate
          color="black"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>

      <div v-show="!loading">
        <v-card-title class="text-h5 grey lighten-2">
          Usuario: {{ user_details.name }}
        </v-card-title>

        <v-card-text>
          <v-list>

            <div v-for="(value, key, index) in user_details" :key="`item_list_${index}`">
              <v-list-item two-line v-if="value !== undefined && value !== null">

                <v-list-item-content v-if="key == 'company_id'">
                  <v-list-item-title>{{ parser[key] }}</v-list-item-title>

                  <v-list-item-subtitle>
                    <span v-if="!value || !value.length">Sin empresas asociadas</span>

                    <div v-else>
                      <div v-for="(item_id, idx) in value" :key="`item_company_${idx}`">
                        - {{ getCompanyName(item_id) }}
                      </div>

                    </div>
                  </v-list-item-subtitle>

                </v-list-item-content>

                <v-list-item-content v-else>
                  <v-list-item-title>{{ parser[key] }}</v-list-item-title>
                  <v-list-item-subtitle>{{ parseValue(key, value) }}</v-list-item-subtitle>
                </v-list-item-content>

              </v-list-item>
            </div>

          </v-list>

        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">
            CERRAR
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

  <script>
  export default {
    data: () => {
      return {
        showing_modal: false,
        loading: true,
        user_details: {},
      }
    },
    mounted() {
    },
    methods: {
      getCompanyName(id) {
        const founded = this.getAllCompaniesList.find(el => el.id == id)
        return founded.name
      },
      parseValue(key, value) {
        if (key == 'status') return value? 'Activo' : 'No activo'
        if (key == 'enabled') return value? 'Funciona' : 'No funciona'
        if (value === false) return 'No'
        if (value === true) return 'Si'

        return value
      },
      async open(id) {
        // console.log(item)
        this.loading = true

        try {
          const item = await this.$api.getOneUserInfo(id)
          this.user_details = item

          this.loading = false
          this.showing_modal = true
        } catch (e) {
          console.log(e);
        }

      },

      close() {
        this.showing_modal = false;
        this.loading = true
        this.user_details = {}
      },

    },
    computed: {
      getAllCompaniesList() { return this.$store.getters['session/getAllCompaniesList']() },

      parser() {
        const parser = {
          email: 'Email',
          country: 'País',
          company_id: 'Empresas asociadas',
          name: 'Nombre',
          role: 'Rol',
          username: 'Usuario',
        }
        return parser
      }
    },
  }
  </script>
